import { FC } from 'react';

import { PriceRendering } from '@sitecore/types/manual/PriceRendering';
import { Price as SparkyPrice } from '@sparky';

export const Price: FC<PriceRendering> = ({ fields, params }) => {
  if (!fields) {
    return null;
  }

  return (
    <SparkyPrice
      discountPrice={fields.discountPriceText.value}
      discountPriceAriaText={fields.discountPriceAriaText.value}
      originalPrice={fields.originalPriceText.value}
      originalPriceAriaText={fields.originalPriceAriaText.value}
      text={fields.text?.value}
      size={params?.size ? params.size : 'M'}
      suffixText={fields?.suffixText?.value}
    />
  );
};

export default Price;
